<template>
    <div class="index-ware">
        <transition name="fade">
            <div class="sliderBg" @click="$emit('update:ctrlDetailPop', false)"></div>
        </transition>
        <transition name="slider-customer">
            <div class="sliderBody">
                <!-- <div class="title">
                    {{targetDetail.targetTitle}}
                    <span class="pull-right text-hover">
                        <i class="iconfont icon-close" @click="isActive=false"></i>
                    </span>
                </div> -->
                <div class="targetMain MXscroll">
                    <div class="steps">
                        <div class="day"></div>
                        <template v-for="(item, m) in targetDetail.valueList">
                            <div class="step" :key="m">
                                <!-- 时间 -->
                                <span class="content-l">{{item[targetDetail.leftKeys[0]].split(' ')[0]}}</span>
                                <span class="dot"></span>
                                <!-- <span class="target">{{targetTitle}}</span> -->
                                <div class="right-ware">
                                    <!-- 新增跟进数要展示跟进内容 trackContent -->
                                    <template v-for="itemKey in targetDetail.rightKeys">
                                        <template v-if="item.hasOwnProperty(itemKey)">
                                            <span v-if="itemKey === 'trackContent'" :key="itemKey" :title="item[itemKey]" class="content-desc">{{item[itemKey]}}</span>
                                        </template>
                                    </template>
                                    <template v-for="itemKey in targetDetail.rightKeys">
                                        <template v-if="item.hasOwnProperty(itemKey)">
                                            <span v-if="itemKey != 'trackContent'" :key="itemKey" :title="item[itemKey]" class="content-r" @click="jumpToDetail(item[targetDetail.billId])">{{item[itemKey]}}</span>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import mixins from '@/mixin/index'
export default {
    name: 'TargetDetail',
    mixins: [mixins],
    props: {
        targetDetail: {
            type: Object
        }
    },
    components: {},
    data() {
        return {
        }
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() { },
    methods: {
        jumpToDetail(billId) {
            if (this.targetDetail.isJump === 2) {
                // this.$emit('update:ctrlDetailPop', false)
                this.clientDetailPage({
                    moduleCode: this.targetDetail.moduleCode,
                    billId: billId
                })
            }
        }
    },
    beforeDestroy() {
    },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
